label, p.label {
  font-weight: bold;
  font-size: 100%;
  line-height: 1.5em;
  padding: 0 0 0 10px;
  margin: 0;
  font-style: normal;
  vertical-align: top;/* sets labels to top of div row */
}

label + fieldset,
p.label + fieldset{
  display: inline-block;
  margin: 0;
}


h2 label {
  padding: 0 5px;
}
.box-round {
    > fieldset,
    > div > fieldset,
    > form > fieldset,
    > .fluid-form,
    > div > .fluid-form,
    > form > .fluid-form {
        margin: 0 10px 10px 10px;
    }
}

.drawerPanel .fluid-form {
  margin: 10px;
}

.drawerPanel .psDialog.ui-dialog-content {
  > .fluid-form:first-child,
  > form:first-child > .fluid-form:first-child,
  > div:first-child > form:first-child > .fluid-form:first-child {
    margin-top: 0px;
  }
}

.fluid-form {
  display: flex;
  flex-direction: column;
  margin: 0 20px 20px;
  border: @border-style;
  border-radius: 4px;
  > h2:first-child {
    margin: 0px 10px 10px 10px;
    padding-top: 5px;
  }
  > div:not(.button-row) {
    margin: 0 10px 10px 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &:first-child {
      margin-top: 10px;
    }
    &:nth-of-type(odd):not(.button-row) {
      background-color: @zebra;
    }
    &:nth-of-type(even):not(.button-row) {
      background-color: @zebra-alt;
    }
    > div {
      margin-left: 5px;
      margin-right: 5px;
      margin-top: 10px;
      margin-bottom: 5px;
      input[type=checkbox] + label {
        display: inline-block;
        padding-left: 0;
        vertical-align: top;
      }
	  > span + label {
        display: inline-block;
        padding-left: 0;
      }
      > label {
        padding-left: 5px;
        display: block;
      }
      > input[type=text], select.minwidth {
        width: 200px;
      }
    }
  }
  .searchButtons a:first-child {
    margin: 0;
  }
  > div.assorted-input-row > div {
    button {
      margin-left: 0px;
    }
    input[type=checkbox] {
      margin-top: 0;
      vertical-align: middle;
      margin-left: 10px;
      & + label {
        vertical-align: middle;
        padding-bottom: 1px;
      }
    }
  }
  > div > div.sub-row {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    & > input[type="text"] {
      width: unset;
    }
    & > label + span {
      display: flex;
      flex-flow: row wrap;
      > input {
        flex-grow: 1;
      }
      > input + em + p {
        flex-basis: 100%;
      }
      > select {
        flex-grow: 1;
      }
      > select + em + p {
        flex-basis: 100%;
      }
    }
  }
}

.fluid-form.no-border {
  border: none;
}

.fluid-multi-form,
.fluid-multi-form-no-strip {
    > h2 {
        margin-bottom: 0;
    }
    > div.fluid-form {
        border: 0;
        margin: 0 0 10px 0;
    }
} 

.fluid-multi-form-no-strip {
    > div.fluid-form {
        > div:not(.button-row) {
            &:nth-of-type(odd):not(.button-row) {
              background-color: white;
            }
            &:nth-of-type(even):not(.button-row) {
              background-color: white;
            }
        }
    }    
} 

/* Start Fieldset Rules */
fieldset {
  margin: 0 20px 20px;
  border: @border-style;
  border-radius: 4px;
  div.box-round + &,
  fieldset > div + fieldset,
  td & {
    margin: 0;
  }
  & + & {
    margin-top: 10px;
  }
  &.feedback-active,
  &.feedback-active > legend {
    border: 2px solid @alert-info-foreground;
  }
  > div:first-child {
      margin-top: 10px;
  }
    h1.toggle,
    h2.toggle,
    h3.toggle,
    h4.toggle,
    h5.toggle,
    fieldset {
      margin: 0 10px 10px 10px;
    }
    h1.expanded,
    h2.expanded,
    h3.expanded,
    h4.expanded,
    h5.expanded {
      margin-bottom: 0;
    }
    &.search-parameters > div > a {
      display: inline-block;
      min-width: .75em;
      text-align: center;
      border-radius: 4px;
      padding: 0 0.4em;
      &:hover {
        background-color: @light-med-blue;
      }
      &:first-child {
        margin-left: 5px;
      }
      &:last-of-type {
        margin-right: 5px;
      }
    }
  > div {
    padding: 4px 0 4px 0;
    margin: 0 10px 10px 10px;
    fieldset {
      margin: 0 10px 10px 10px;
      width: calc(~"100% - 25px");
    }
    label + fieldset {
      margin: 0 0 10px 0;
      width: calc(~"100% - 35% - 25px");
    }
    > strong:first-child {
      margin-left: 10px;
      margin-right: 10px;
    }
    > label + span {
      display: inline-block;
      width: 60%;
    }

    > label.selected-value + span {
      max-height: 40px;
      overflow-y: auto;
    }

    > label + div {
        display: inline-block;
        width: 60%;
        &.wrap-word {
            overflow-wrap: break-word;
        }
      }
  }
  span {
    >input[type="radio"],
    >input[type="checkbox"] {
      vertical-align: top;
    }

    >input[type="radio"]+label,
    >input[type="checkbox"]+label {
      white-space: normal;
      margin-right: 30px;
    }

    fieldset {
      width: auto;
    }

  }
  label,
  p.label {
    width: 35%;
    display: inline-block;
    margin: 0;
  }
  td label {
    padding-left: 0;
	width: auto;
  }
  > span,
  p span {
    white-space: nowrap;
    display: inline-block;
  }

  > div {
    > input:first-child {
      margin-left: 5px;
    }
    > select:first-child {
      margin-left: 10px;
    }
    &:nth-of-type(odd):not(.button-row, .pds-grid-widget-filter, .neon-abstract-input-info, .neon-radio-field-inputs){
      background-color: @zebra;
    }
    &:nth-of-type(even):not(.button-row, .pds-grid-widget-filter, .neon-abstract-input-info, .neon-radio-field-inputs){
      background-color: @zebra-alt;
    }
  }
  div:nth-of-type(odd).ui-jqgrid-hbox {
    background-color: rgba(0, 0, 0, 0);
  }
  > div > span {
    display: inline-block;
    white-space: nowrap;
    label {
      min-width: 100px;
    }
    textarea {
        white-space: normal;
    }
  }

  > div > span.pickerContainer {
    width: 100%
  }

  div > span > label.selected-value {
    padding-left: 5px;
  }

  div > span > label.selected-value > span {
    font-weight: normal;
  }

  div > span label {
    display: inline-block;
    margin-left: 0;
    padding-left: 0;
  }

  div > span fieldset label {
    margin-left: 10px;
  }

  .box-round & > p:first-child,
  > p:first-child {
    margin-top: 0;
  }

  .box-round & > p,
  > p {
    margin-bottom: 0;
    margin-left: 10px;
  }
  div.col3:first-child {
    margin: 0 0 0 10px;
  }

  /* inline div columns instead of rows, used in attendance grid */
  &.divColumns {
    vertical-align: top;
    &.cols3,
    .box-round h2.toggle + div > &.cols3 {
      display: inline-block;
      width: 30%;
      margin-right: 0;
    }

    > div,
    > div:nth-of-type(odd){
      border: 1px solid #ebebeb;
      border-radius: 4px;
      display: inline-block;
      padding: 2px 5px;
      margin: 10px 0 10px 10px;
      min-width: 40px;
      background-color: rgba(255, 255, 255, 1);
      vertical-align: top;
    }

    > div.button-row {
      display: block;
      margin-top: 10px;
      padding: 0;
    }

    > div.button-box {
      padding-top: 1.7em;
      border: none;
    }

    span {
      margin-right: 0;
    }

    label, &.divColumns label, p.label, &.divColumns p.label {
      display: block;
      padding: 0;
      width: auto;
    }

    input[type="radio"] + label,
    input[type="checkbox"] + label {
      width: auto;
      display: inline-block;
    }

    div.listManagementButtons {
      margin-top: 40px;
      padding-top: 5px;
      padding-right: 0;
      padding-bottom: 5px;
      padding-left: 0;
    }

    .listManagementButtons button {
      width: -webkit-calc(~"100% - 10px");
      width: calc(~"100% - 10px");
    }
    .error-message {
      margin-left: 0; /* label, input, and error all align vertically */
    }
  }
  input[readonly].indirectEntry {
    background-color: #E7E7E7;
    border: 1px solid  @border-color;
    padding: 3px;
  }

  .box-round & .error-message,
  & .error-message {
    margin-left: -webkit-calc(~"35% + 15px"); /* has to match to label width */
    margin-left: calc(~"35% + 15px"); /* has to match to label width */
  }

  .box-round & td .error-message,
  & td .error-message {
    margin-left: 15px; /* inside of a table */
  }
  legend {
    font-weight: bold;
    font-size: 128%;//matches H2
    margin-left: 10px;
    margin-bottom: 5px;
    padding: 5px 10px;
    border: @border-style;
    border-radius: 4px;
    span.filtersHeader{
      color: #8B0000;
    }
    &.toggle.collapsed {
      background-image: url("../img/arrow-right-blk.svg");
      background-repeat: no-repeat;
      background-position: 0.35em center;
      padding-left: 1.2em;
      cursor: pointer;
      margin-bottom: 1px;
    }
    &.toggle.expanded {
      background-image: url("../img/arrow-down-blk.svg");
      background-repeat: no-repeat;
      background-position: 0.35em center;
      padding-left: 1.2em;
      cursor: pointer;
      margin-bottom: 1px;
    }
  }

  > div > div.modal-comment {
    padding: 0 10px;
    > label {
      display: block;
      padding: 5px;
    }
    > textarea {
      width: 100%;
      height: 70px;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      min-height: 70px;
      max-height: 200px;
      resize: vertical;
      margin-top: 0;
      margin-bottom: 0;
      display: block;
      padding: 5px;
    }
  }
  &.no-border {
    margin: 0;
    border: none;  
    h3 {
      border-top: none;
    }
  }
}
/* End Fieldset Rules */
/* Form Rules */
input[type="radio"] + label,
input[type="checkbox"] + label,
input[type="radio"] + input[type="hidden"] + label,
input[type="checkbox"] + input[type="hidden"] + label {
  padding-left: 0;
  width: auto;
  vertical-align: baseline; /* resets the vertical align to baseline from the div label rule */
}

span input[type="radio"],
span input[type="checkbox"] {
  margin-left: 5px;
}

span input[type="radio"] + label,
span input[type="checkbox"] + label {
  padding-left: 0;
  margin-right: 20px;
  width: auto;
  min-width: 100px;
}

input[type="radio"],
input[type="checkbox"] {
  margin-right: 5px;
  margin-top: 0.3em;
}

.classicRadioList label {
    font-weight: normal;
    padding-inline-start: 0px;
    padding-inline-end: 3px;
}

.classicRadioList input[type="radio"] {
    margin-inline-end: 3px;
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea,
select {
  font-size: 100%;
  padding: 3px;
  background-color: #FFF;
}

/* Radio Slider form elements */
input.radioSlider {
  position: absolute;
  left: -9999px;
}

label.radioSlider {
  border: 1px solid @border-color;
  line-height: 1.1em;
  display: inline-block;
  border-radius: 4px;
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.4);
  width: auto;
  &.on {
    background-color: @med-blue;
    color: white;
    span.txtOff {
      display: none;
    }
  }
  &.off {
    background-color: #FFF;
    color: inherit;
    span.txtOn {
      display: none;
    }
  }
  input.radioSlider:focus + & {
    outline: #11aaeb solid 1px;
  }
  span {
    padding: 5px;
  }
  em {
    display: inline-block;
    width: 1.1em;
    height: 1.1em;
    border-radius: .75em;
    border: 1px solid @border-color;
    color: #000000;
    background-color: #E7E7E7;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
  }
}


.disabled,
input.disabled,
button.disabled,
button.disabled,
input[disabled='disabled'],
button[disabled='disabled'],
input[disabled],
input[disabled]+label,
button[disabled],
input[disabled]+.ui-datepicker-trigger,
input[disabled]+.ui-datepicker-trigger+.ui-datepicker-append,
textarea[disabled],
select[disabled] {
  opacity: 0.45;
  box-shadow: none;
  cursor: default;
}

input.disabled,
input[disabled='disabled'],
input[disabled],
textarea[disabled],
select[disabled] {
  background-color: #FFF;
}

input[disabled].psDateWidget.jumpDate+.ui-datepicker-trigger {
  /* for accessibility we should not disable this field */
  opacity: 1;
  padding-bottom: 3px;
}

input.disabled:hover,
input[disabled='disabled']:hover,
input[disabled]:hover {

}

input[readonly] {
    border: none;
    background-color: transparent;
    padding-left: 0;
    +.ui-datepicker-trigger {
        display: none;
    }
}

textarea[readonly] {
    border: none;
    background-color: transparent;
    padding-left: 0;
}

input[readonly].plain {
  border: 1px solid  @border-color;
  background-color: white;
}

/* Alter Fluid Form code... a bit */
.stepped-container {
  height: calc(~"97vh - 100px");
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
.stepped-container .step-content #feedback-message {
  margin: 3px 0;
}
.stepped-container .step-content .feedback-error {
  margin: 3px 0;
}
.stepped-container .fluid-form {
  border: none;
  margin: 0;
}
.stepped-container .fluid-form .fluid-form-inline {
  margin: 15px 5px 5px 5px;
  >  input[disabled]+label {
    opacity: 1;
  }
  > input[type=text] {
    display: inline-block;
    width: 100px;
  }

  > label {
    margin-right: 10px;
    display: inline-block;
  }
}
.stepped-container .fluid-form .fluid-form-double-wide {
  width: 45%;
  > input[type=text] {
    margin-right: 10px;
  }
  > b.dpWrapper+em {
    font-size: 150%;
    margin-left: 5px;
    vertical-align: top;
  }
  > label {
  	padding: 0px 10px 0px 5px;
  }
}
#psDialogDocked.psDialog .stepped-container .box-round {
  width: calc(~"100% - 12px");
}
.stepped-container .fluid-form > div {
  width: 100%;
  margin: 0;
  > div.angucomplete-container {
    width: 95%;
  }
  > div {
    width: 100%;
    > input[type=text] {
      width: 95%;
    }
    > textarea {
      width: 95%;
      min-width: 95%;
      max-width: 95%;
    }
    > label.nopadding {
      padding: 0;
    }
    > h4 {
      margin: 10px 0px 0px 5px;
    }
    > div:not(.no-margin-container) {
      margin-left: 5px;
      margin-right: 5px;
      margin-top: 10px;
      margin-bottom: 5px;
    }
    > div {
      > input[type=text] {
        width: 95%;
      }
      > select {
        margin: 0px 0px 5px 0px;
        min-width: 20%;
      }
      > textarea {
        width: 95%;
        min-width: 95%;
        max-width: 95%;
        margin: 0px 0px 5px 0px;
      }
      > label {
        display: block;
        padding-left: 5px;
      }
      > div:not(.no-margin-container) {
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 10px;
        margin-bottom: 5px;
      }
      > div {
        width: 100%;
        > input[type=text] {
          margin: 0px 0px 5px 5px;
          width: 95%;
        }
        > select {
          margin: 0px 0px 5px 5px;
          min-width: 20%;
        }
        > textarea {
          width: 95%;
          min-width: 95%;
          max-width: 95%;
          margin: 0px 0px 0px 5px;
        }
        > input[type=checkbox] + label {
          display: inline-block;
          padding-left: 0;
        }
        > label {
          display: block;
        }
        > div:not(.no-margin-container) {
          margin-left: 5px;
          margin-right: 5px;
          margin-top: 10px;
          margin-bottom: 5px;
        }
        > div {
          width: 100%;
          > input[type=text] {
            margin: 0px 0px 5px 5px;
            width: 95%;
          }
          > select {
            margin: 0px 0px 5px 5px;
            min-width: 20%;
          }
          > textarea {
            width: 95%;
            min-width: 95%;
            max-width: 95%;
            margin: 0px 0px 5px 5px;
          }
          > input[type=checkbox] + label {
            display: inline-block;
            padding-left: 0;
          }
          > label {
            display: block;
          }
          > b.dpWrapper {
            margin-left: 5px;
          }
        }
      }
    }
  }
}
.stepped-container .fluid-form input[type=text] {
  width: 100%;
}
.stepped-container .steps-container {
  -webkit-flex: initial;
  -moz-flex: initial;
  -ms-flex: initial;
  flex: initial;
  min-width: 250px;
  max-width: 250px;
  overflow: auto;
}
.stepped-container .steps-container ul {
  margin: 20px 40px;
  list-style-type: none;
}
.stepped-container .steps-container ul li {
  width: 100%;
  line-height: 40px;
  list-style-type: none;
  position: relative;
}
.stepped-container .steps-container ul li a,
.stepped-container .steps-container ul li span {
  width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
}
.stepped-container .steps-container ul li:before {
  margin-left: -5px;
  margin-right: 10px;
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid #ccc;
  border-radius: 50%;
  vertical-align: middle;
  background-color: #fff;
}
.stepped-container .steps-container ul li:after {
  content: "";
  position: absolute;
  top: 20px;
  left: 5px;
  right: auto;
  bottom: 0;
  display: inline-block;
  width: 1px;
  height: 40px;
  background-color: #ccc;
  z-index: -1;
}
.stepped-container .steps-container ul li:last-of-type:after {
  display: none;
}
.stepped-container .steps-container ul li.active:before {
  border: 1px solid #042d40;
  background-color: #042d40;
}
.stepped-container .steps-container ul li.completed:before {
  border: 1px solid #042d40;
  background-image: url("../img/icon-check-blue.svg");
  background-repeat: no-repeat;
  background-size: 70%;
  background-position: center;
}
.stepped-container .steps-container ul li.completed:after {
  background-color: #042d40;
}
.stepped-container .step-content {
  overflow: auto;
  padding-right: 20px;
  width: 100%;
}
.stepped-container .step-content h2 {
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}
.stepped-container .step-content p {
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}
.angucomplete-holder {
  position: relative;
}
.angucomplete-input {
  width:100%;
}
.angucomplete-dropdown {
  border-color: @border-color;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  width: 100%;
  cursor: pointer;
  z-index: 9999;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 348px;
  margin-top: 0px;
  background-color: #FFFFFF;
}
.angucomplete-searching {
  color: @med-blue;
  font-size: 14px;
  padding: 7px 5px 7px 11px;
  border-right: 6px solid transparent;
}
.angucomplete-description {
  font-size: 14px;
}
.angucomplete-row {
  padding: 7px 5px 7px 11px;
  border-right: 6px solid transparent;
  overflow: hidden;
  color: @button-color;
  clear: both;
  :hover {

  }
}
.angucomplete-selected-row {
  background-color: @light-med-blue;
  color: @link-color-hover;
}

select.attendance-codes {
  max-width: 200px;
}

select.drawer-select-width {
  min-width: 185px;
  max-width: 275px;
}

/* autocomplete dropdown that acts like combo box */
.fluid-form .autocomplete-combobox-container {
    padding: 7px 0 0 0;
}
.fluid-form .autocomplete-combobox-container > span {
    width: calc(~"91% - 1px")!important;
    margin: 0px 0 10px 5px;
}
.fluid-form .autocomplete-combobox-container > button {
    margin: 0 0 5px 12px;
}
.fluid-form .autocomplete-combobox-container .angucomplete-dropdown {
    width: calc(~"100% + 24px")!important;
}
.fluid-form .autocomplete-combobox-container .input_bg {
   background: url("../dropdown_image.png") no-repeat;
   background-position: calc(~"100% - 1px");
   background-size: 37px;
   border: #aaa solid .5px;
   padding-right: 21px !important;
   background-color: #fff;
   line-height: 1.4em;
   [dir=rtl] & {
     background-position: -20px;
   }
}
.fluid-form .autocomplete-combobox-container .angucomplete-row {
  height: 1.4em;
}

.fluid-form>div:not(.button-row)>div.button-group {
  margin: auto;
}

.psDialog .fluid-form p.error-message {
  margin: 0;
}
/* This fakery allows a button to look like a link - Required to get Mac VoiceOver to read the visible content
   of a link in a grid instead of saying "blank" - Tried to use button.as-link but Safari did not recognize it */
.button-as-link{
  background: none;
  background-color: white;
  border: none;
  padding: 0;
  cursor: pointer;
  color: @link-color;
}
.button-as-link:hover{
  color: @link-color-hover;
  background-color: white;
}
.button-as-link:focus{
  color: @link-color;
  background-color: white;
}#fakeBody .shift-right{
    float:right;
    text-align:right;width:150px;
    margin: 25px 20px;
     margin-top: 30px;Attendance By Class
     margin-right: 30px;
     margin-bottom: 25px;
     margin-left: 20px;
    font-size:145%
 }
.displayFlex{
    display: flex;
}

.ruleNotApplicable {
  font-weight: normal;
  padding: 0 0 0 5px;
}

.angucomplete-row div.angucomplete-title {
  overflow: hidden;
  text-overflow: ellipsis;
}
